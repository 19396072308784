export default defineAppConfig({
  saasName: 'La Tienda Home',
  saasLink: 'https://lth.fsbd.ai',
  customSaas: true,
  languageStrings: {
    company: 'Departamento',
    companies: 'Departamentos',
    briefing: {
      visibility: 'Si el briefing se pone en "oculto" únicamente lo visualizarán los usuarios admin, y en "visible" todos los usuarios de cualquier departamento.'
    }
  },
  analyticsLooker: 'b4908b90-6c71-4557-8663-66c50ab819dc',
  adify: {
    actions: ['Aumentar', 'Reducir', 'Pausar', 'Mantener', 'Revisar'],
    platforms: ['Google Ads', 'Facebook Ads'],
    vertical: false,
    country: false
  },
  savvywords: {
    customers: []
  },
  insights: {
    filterByCompany: false,
    filterByPlatform: false
  },
  meta: {
    adAccounts: [
      {
        name: 'Redes Lesap (116508232151666)',
        act: 116508232151666
      },
      {
        name: 'La Tienda Home (983393131746579)',
        act: 983393131746579
      }
    ]
  }
})